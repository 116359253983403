import React from 'react';
import { _ } from '../lib/l18n';

import coinsIconSrc from '../assets/icons/coins-colour.svg';
import plainCoinsIconSrc from '../assets/icons/coins-colour.svg';
import { useBrandingStore } from '../state/store';

const CoinsIcon: React.FC<{ plain?: boolean; className?: string }> = ({ className, plain = false }) => {
  const { icon_double } = useBrandingStore((s) => s.branding);
  const iconSrc = icon_double ? icon_double : plain ? plainCoinsIconSrc : coinsIconSrc;
  return <img src={iconSrc} alt={_('coins')} className={`icon-double ${className || ''}`} />;
};

export default CoinsIcon;
