import React from 'react';
import CoinsIcon from './CoinsIcon';

const Coins: React.FC<{ amount: number; className?: string }> = ({ amount, className }) => {
  return (
    <div className={`flex items-center ${className || ''}`}>
      <CoinsIcon className="mr-2" />
      {amount.toLocaleString()}
    </div>
  );
};

export default Coins;
