import i18next, { TOptions } from 'i18next';
import i18nextLanguageDetector from 'i18next-browser-languagedetector';
import i18nextHttpBackend from 'i18next-http-backend';
import { diff } from './date';
import { ItemType } from './types';
import { StoreFilter } from './types.local';

const i18Instance = i18next.createInstance();

// Initialise i18next.
export async function init() {
  return i18Instance
    .use(i18nextHttpBackend)
    .use(i18nextLanguageDetector)
    .init({
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      defaultNS: 'store',
      detection: {
        order: ['querystring', 'navigator'],
        caches: [],
      },
      fallbackNS: 'common',
      fallbackLng: 'en',
      ns: ['store', 'transaction', 'redemption', 'validation', 'common'],
    });
}

export const getChanceOfWinningSentence = (chanceAsFloat: number): string => {
  const chancePc = Math.round(chanceAsFloat * 100);
  const pc = chanceAsFloat <= 0 ? 0 : chancePc < 1 ? '<1' : chancePc;
  return _('raffle.chanceToWin', { chance: pc });
};

const genericLoadingMessages: [string, string][] = [
  ['loading.hangTight', 'loading.liftingParcels'],
  ['loading.oneMoment', 'loading.stockingShelves'],
  ['loading.justSecond', 'loading.deliveringGoods'],
];
let genericLoadingMessageCounter = 0;
export const getGenericLoadingMessage = (): [string, string] => {
  return genericLoadingMessages[genericLoadingMessageCounter++ % genericLoadingMessages.length] || genericLoadingMessages[0];
};

export const getTypeName = (type: ItemType) => {
  let typeName = _('unknown');
  if (type === ItemType.Auction) typeName = _('typeAuction');
  if (type === ItemType.Purchase) typeName = _('typeBuyNow');
  if (type === ItemType.Raffle) typeName = _('typeRaffle');
  if (type === ItemType.Contribution) typeName = _('item.type.contribution');
  return typeName;
};

export const getTypeNameFromFilter = (filter: StoreFilter) => {
  let typeName = '';
  if (filter === StoreFilter.Auction) typeName = getTypeName(ItemType.Auction);
  if (filter === StoreFilter.BuyNow) typeName = getTypeName(ItemType.Purchase);
  if (filter === StoreFilter.Raffle) typeName = getTypeName(ItemType.Raffle);
  return typeName;
};

export const timeLeft = (date: Date) => {
  const timeDiff = diff(date, new Date());
  let timeStr;
  if (timeDiff < 60) {
    timeStr = _('lessThanOneMinLeft');
  } else if (timeDiff < 5000) {
    timeStr = _('minsLeft', { mins: Math.ceil(timeDiff / 60) });
  } else if (timeDiff < 100000) {
    timeStr = _('hoursLeft', { hours: Math.ceil(timeDiff / 3660) });
  } else {
    timeStr = _('daysLeft', { days: Math.ceil(timeDiff / 86400) });
  }
  return timeStr;
};

export const _ = (str: string, args?: { [index: string]: string | number }) => {
  let finalArgs: TOptions = args || {};
  if (finalArgs) {
    // We do not escape the values or it leads to double escaping by React.
    finalArgs = { ...finalArgs, interpolation: { escapeValue: false } };
  }
  return i18Instance.t(str, finalArgs);
};

export const exists = (str: string) => {
  return i18Instance.exists(str);
};
