import { FormikProps, useFormik } from 'formik';
import React from 'react';
import { _ } from '../../lib/l18n';
import { Redemption } from '../../lib/types';
import Button from '../Button';

const SelfRedeemForm: React.FC<{ redemptions: Redemption[]; onSubmit: () => void; isLoading?: boolean }> = ({
  redemptions,
  onSubmit,
  isLoading,
}) => {
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      onSubmit();
    },
  });
  let message = _('confirm.redeemitems', { number: redemptions.length || '?' });
  if (redemptions.length === 1) {
    message = _('confirm.redeemitem', { item: redemptions[0].item.name || '?' });
  }
  return <SelfRedeemFormInternal form={formik} isLoading={isLoading} message={message} />;
};

const SelfRedeemFormInternal: React.FC<{ message: string; form: FormikProps<{}>; isLoading?: boolean }> = ({
  message,
  form,
  isLoading,
}) => {
  return (
    <div className="max-w-md">
      <p className="mb-8">{message}</p>
      <div className="max-w-sm mx-auto">
        <form onSubmit={form.handleSubmit}>
          <div className="max-w-sm">
            <div className="mt-4">
              <div>
                <Button primary isSubmit disabled={isLoading} spin={isLoading}>
                  {_('common:confirm')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SelfRedeemForm;
