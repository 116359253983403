import { Field, Form, Formik, useField } from 'formik';
import React, { useMemo } from 'react';
import { _ } from '../../lib/l18n';
import { Redemption } from '../../lib/types';
import { getShippingRedemptionSchema } from '../../lib/validation';
import { getCountryList, getCountryStates } from '../../lib/world';
import Button from '../Button';

const FieldBase: React.FC<any> = ({ label, children, ...props }) => {
  const [, meta] = useField(props);
  const hasError = meta.touched && meta.error;
  const fieldId = props.id || props.name;
  return (
    <div className="flex flex-col md:flex-row my-4 md:my-2">
      <div className="md:flex-1 md:mr-4 mb-2 md:mb-0 md:pt-2">
        <label className="font-medium" htmlFor={fieldId}>
          {label}
        </label>
      </div>
      <div className="md:flex-3">
        {children}
        {hasError ? (
          <div className="mt-2 text-red" id={`${fieldId}-error`}>
            {meta.error}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const TextField: React.FC<any> = (props) => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error;
  const fieldId = props.id || props.name;
  return (
    <FieldBase {...props}>
      <Field
        as="input"
        type="text"
        id={fieldId}
        className={`w-full ${!hasError ? 'border-grey-30' : 'border-red'} shadow-item border rounded px-4 py-2`}
        aria-invalid={hasError ? 'true' : null}
        aria-describedby={hasError ? `${fieldId}-error` : null}
        {...field}
        {...props}
      />
    </FieldBase>
  );
};

const CountryField: React.FC<any & { label: string; allowedCountries?: string[] }> = ({ allowedCountries, ...props }) => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error;
  const fieldId = props.id || props.name;

  const allCountries = getCountryList();
  const countryCodes = useMemo(() => {
    let countryCodes = Object.keys(allCountries);
    if (allowedCountries) {
      countryCodes = countryCodes.filter((c) => allowedCountries.includes(c));
    }
    return countryCodes;
  }, [allCountries, allowedCountries]);

  return (
    <FieldBase {...props}>
      <Field
        as="select"
        id={fieldId}
        className={`w-full bg-white ${!hasError ? 'border-grey-30' : 'border-red'} shadow-item border rounded px-4 py-2`}
        aria-invalid={hasError ? 'true' : null}
        aria-describedby={hasError ? `${fieldId}-error` : null}
        {...field}
        {...props}
      >
        {countryCodes.length > 1 ? <option value="">--</option> : null}
        {countryCodes.map((code) => {
          return (
            <option key={code} value={code}>
              {allCountries[code]}
            </option>
          );
        })}
      </Field>
    </FieldBase>
  );
};

const StateField: React.FC<any & { country: string }> = ({ country, ...props }) => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error;
  const fieldId = props.id || props.name;
  const allStates = getCountryStates(country) || {};
  const stateCodes: string[] = Object.keys(allStates);
  return (
    <FieldBase {...props}>
      {stateCodes.length ? (
        <Field
          as="select"
          id={fieldId}
          className={`w-full bg-white ${!hasError ? 'border-grey-30' : 'border-red'} shadow-item border rounded px-4 py-2`}
          aria-invalid={hasError ? 'true' : null}
          aria-describedby={hasError ? `${fieldId}-error` : null}
          {...field}
          {...props}
        >
          {stateCodes.length > 1 ? <option value="">--</option> : null}
          {stateCodes.map((code) => {
            return (
              <option key={code} value={code}>
                {allStates[code]}
              </option>
            );
          })}
        </Field>
      ) : (
        <input
          className={`w-full ${!hasError ? 'border-grey-30' : 'border-red'} shadow-item border rounded px-4 py-2`}
          aria-invalid={hasError ? 'true' : null}
          aria-describedby={hasError ? `${fieldId}-error` : null}
          type="text"
          disabled={!country}
          {...field}
          {...props}
        />
      )}
    </FieldBase>
  );
};

const ShippingRedeemForm: React.FC<{ redemptions: Redemption[]; onSubmit: (data: any) => void; isLoading?: boolean }> = ({
  redemptions,
  ...props
}) => {
  return <ShippingRedeemFormInternal options={redemptions[0].options} {...props} />;
};

const ShippingRedeemFormInternal: React.FC<{
  options?: Redemption['options'];
  onSubmit: (data: any) => void;
  isLoading?: boolean;
}> = ({ options: rawOptions, onSubmit, isLoading }) => {
  const options = rawOptions || {};
  const useStates = typeof options.use_states !== 'undefined' ? options.use_states : true;

  const schema = useMemo(() => {
    return getShippingRedemptionSchema(options.additional_required_fields);
  }, [rawOptions]); // eslint-disable-line

  return (
    <div className="max-w-lg w-full">
      <p className="mb-8">{_('redemption:shipping.provideYourDetails')}</p>
      <div className="md:max-w-lg md:mx-auto">
        <Formik
          initialValues={{
            company: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            addressline1: '',
            addressline2: '',
            city: '',
            postcode: '',
            country: options?.allowed_countries?.length === 1 ? options?.allowed_countries[0] : '',
            state: '',
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ values }) => (
            <Form>
              <TextField label={_('company')} name="company" />
              <TextField label={_('firstName')} name="firstname" />
              <TextField label={_('lastName')} name="lastname" />
              <TextField label={_('email')} name="email" type="email" />
              <TextField label={_('phone')} name="phone" />
              <TextField label={_('address')} name="addressline1" placeholder={_('addressLine1')} />
              <TextField label="" name="addressline2" placeholder={_('addressLine2')} />
              <TextField label={_('city')} name="city" />
              <TextField label={_('zipPostCode')} name="postcode" />
              <CountryField label={_('country')} name="country" allowedCountries={options?.allowed_countries} />
              {useStates ? <StateField label={_('stateProvince')} name="state" country={values.country} /> : null}
              <div className="mt-4">
                <Button primary isSubmit disabled={isLoading} spin={isLoading}>
                  {_('redemption:sendRequest')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ShippingRedeemForm;
