import isEqual from 'lodash.isequal';
import React, { useMemo, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import ContentContainer from '../components/layouts/ContentContainer';
import { NotificationError } from '../components/Notification';
import DownloadRedeemForm from '../components/redeem/DownloadRedeemForm';
import ExternalRedeemRedirect from '../components/redeem/ExternalRedeemRedirect';
import RequestRedeemForm from '../components/redeem/RequestRedeemForm';
import SelfRedeemForm from '../components/redeem/SelfRedeemForm';
import ShippingRedeemForm from '../components/redeem/ShippingRedeemForm';
import Spinner from '../components/Spinner';
import { useDownloadRedemptionMutation, useMetaRedemption, useSubmitMetaRedemptionMutation } from '../lib/hooks';
import { _ } from '../lib/l18n';
import { Redemption, RedemptionItem } from '../lib/types';
import { purchaseBulkRedeemThankYouUrl, redemptionsUrl } from '../lib/urls';
import { useBulkRedemptionStore } from '../state/store';

const isDifferentTypeOrOptions = (r1: Redemption, r2: Redemption) => {
  if (r1.type !== r2.type) return true;
  if (r1.state !== r2.state) return true;
  return !isEqual(r1.options, r2.options);
};

const groupRedemptions = (redemptions: Redemption[]) => {
  const grouped = redemptions.reduce<{ [index: string]: Redemption[] }>((carry, redemption, idx) => {
    const type = redemption.type;
    switch (type) {
      case '_download':
        return {
          ...carry,
          [`_download-${idx}`]: [redemption], // Each download is in its own group.
        };
      case '_self':
      case '_request':
        return {
          ...carry,
          [type]: (carry[type] || []).concat(redemption),
        };
      case '_shipping':
        let i = 0;
        let name;
        while (true) {
          name = `${type}-${i}`;
          const group = carry[name] || [];
          if (group.length && isDifferentTypeOrOptions(group[0], redemption)) {
            i++;
            continue;
          }
          break;
        }
        return {
          ...carry,
          [name]: (carry[name] || []).concat(redemption),
        };
    }
    return carry;
  }, {});
  return Object.values(grouped);
};

const PurchaseItemsShowcase: React.FC<{ redemptions: Redemption[] }> = ({ redemptions }) => {
  const items = redemptions.map((r) => r.item);
  const grouped = Object.values(
    items.reduce<{ [index: string]: RedemptionItem & { quantity: number } }>((carry, item) => {
      if (typeof carry[item.id] === 'undefined') {
        carry[item.id] = { ...item, quantity: 0 };
      }
      carry[item.id].quantity += 1;
      return carry;
    }, {})
  );
  return (
    <div className="my-4">
      <table className="w-full">
        {grouped.map((item) => {
          return (
            <tr key={item.id}>
              <td className="align-middle pr-4 py-2">{item.quantity}x</td>
              <td className="align-middle pr-4 py-2">
                <div className="h-8 w-8">
                  <img src={item.image_url} alt="" className="w-full h-full" />
                </div>
              </td>
              <td className="align-middle py-2">{item.name}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

const NonEmptyBulkRedeem: React.FC<{ purchaseIds: string[]; clearStore: () => void }> = ({ purchaseIds, clearStore }) => {
  const { data, isLoading } = useMetaRedemption(purchaseIds, { refetchOnWindowFocus: false });

  if (isLoading) {
    return (
      <ContentContainer className="flex justify-center">
        <Spinner />
      </ContentContainer>
    );
  } else if (!data || !data.redemptions) {
    return (
      <ContentContainer>
        <NotificationError showClose={false}>{_('purchaseAlreadyRedeemed')}</NotificationError>
      </ContentContainer>
    );
  }

  const redemptions = data.redemptions;
  const requiresExternal = redemptions.some((r) => r?.options?.url_only);
  if (requiresExternal) {
    return (
      <ContentContainer className="flex justify-center">
        <ExternalRedeemRedirect url={data.url} />
        <Spinner />
      </ContentContainer>
    );
  }

  return <BulkRedemptionsRedeem redemptions={redemptions} clearStore={clearStore} />;
};

const BulkRedemptionsRedeem: React.FC<{ redemptions: Redemption[]; clearStore: () => void }> = ({ redemptions, clearStore }) => {
  const history = useHistory();
  const groups = useMemo(() => groupRedemptions(redemptions), [redemptions]);
  const [group, setGroup] = useState(0);

  const handleContinue = () => {
    if (group === groups.length - 1) {
      // We're finished.
      clearStore();
      history.push(purchaseBulkRedeemThankYouUrl);
      return;
    }
    setGroup(group + 1);
  };

  return <RedemptionsGroupRedeem redemptions={groups[group]} onContinue={handleContinue} />;
};

const RedemptionsGroupRedeem: React.FC<{ onContinue: () => void; redemptions: Redemption[] }> = ({ onContinue, redemptions }) => {
  const type = redemptions[0].type;
  const options = redemptions[0].options;

  let title = _('redemption:request.title');
  let subtitle = _('redemption:request.subtitle');
  let footnote;
  if (type === '_download') {
    title = _('redemption:download.title');
    subtitle = _('redemption:download.subtitle');
  }

  if (options?.subtitle) {
    subtitle = options.subtitle;
  }
  if (options?.footnote) {
    footnote = options.footnote;
  }

  return (
    <ContentContainer className="flex flex-col items-center">
      <div className="max-w-md">
        <h1 className="text-xl mb-2 text-center">{title}</h1>
        <h2 className="text-lg mb-8 text-center">{subtitle}</h2>
        <PurchaseItemsShowcase redemptions={redemptions} />
      </div>
      <RedemptionsForm redemptions={redemptions} onContinue={onContinue} />
      <div className="max-w-md w-full">{footnote ? <p className="mt-4">{footnote}</p> : null}</div>
    </ContentContainer>
  );
};

type FormProps = { redemptions: Redemption[]; onContinue: () => void };

const RedemptionsForm: React.FC<FormProps> = (props) => {
  const type = props.redemptions[0].type;
  if (type === '_self') {
    return <RedemptionsSelfForm {...props} />;
  } else if (type === '_request') {
    return <RedemptionsRequestForm {...props} />;
  } else if (type === '_download') {
    return <RedemptionsDownloadForm {...props} />;
  } else if (type === '_shipping') {
    return <RedemptionShippingForm {...props} />;
  }
  return <>Unknown type</>;
};

const RedemptionsDownloadForm: React.FC<FormProps> = ({ redemptions, onContinue }) => {
  const mutation = useDownloadRedemptionMutation(redemptions[0]); // We only support one at a time.
  return (
    <div className="max-w-md">
      {mutation.isError ? (
        <div className="my-8">
          <NotificationError showClose={false}>{_('redemption:error.occurredWhileProcessing')}</NotificationError>
        </div>
      ) : null}
      {mutation.isSuccess ? <iframe src={mutation.data?.url} title="file download" className="sr-only" /> : null}
      <DownloadRedeemForm
        redemptions={redemptions}
        onContinue={onContinue}
        onSubmit={() => mutation.mutate()}
        isLoading={mutation.isLoading}
      />
    </div>
  );
};

const RedemptionsRequestForm: React.FC<FormProps> = ({ redemptions, onContinue }) => {
  const mutation = useSubmitMetaRedemptionMutation(redemptions);
  const handleSubmit = (data: any) => {
    mutation.mutate(data, {
      onSuccess: onContinue,
    });
  };
  return (
    <div className="max-w-md">
      {mutation.isError ? (
        <div className="my-8">
          <NotificationError showClose={false}>{_('redemption:error.occurredWhileProcessing')}</NotificationError>
        </div>
      ) : null}
      <RequestRedeemForm redemptions={redemptions} onSubmit={handleSubmit} isLoading={mutation.isLoading} />
    </div>
  );
};

const RedemptionsSelfForm: React.FC<FormProps> = ({ redemptions, onContinue }) => {
  const mutation = useSubmitMetaRedemptionMutation(redemptions);
  const handleSubmit = (data?: any) => {
    mutation.mutate(data, {
      onSuccess: onContinue,
    });
  };
  return (
    <>
      {mutation.isError ? (
        <div className="my-8">
          <NotificationError showClose={false}>{_('redemption:error.occurredWhileProcessing')}</NotificationError>
        </div>
      ) : null}
      <SelfRedeemForm onSubmit={handleSubmit} isLoading={mutation.isLoading} redemptions={redemptions} />
    </>
  );
};

const RedemptionShippingForm: React.FC<FormProps> = ({ redemptions, onContinue }) => {
  const mutation = useSubmitMetaRedemptionMutation(redemptions);
  const handleSubmit = (data: any) => {
    mutation.mutate(data, {
      onSuccess: onContinue,
    });
  };
  return (
    <div className="max-w-lg w-full">
      {mutation.isError ? (
        <div className="my-8">
          <NotificationError showClose={false}>{_('redemption:error.occurredWhileProcessing')}</NotificationError>
        </div>
      ) : null}
      <ShippingRedeemForm redemptions={redemptions} onSubmit={handleSubmit} isLoading={mutation.isLoading} />
    </div>
  );
};

const PurchaseBulkRedeemContainer = () => {
  const { purchaseIds, setPurchaseIds } = useBulkRedemptionStore();

  // TODO when purchaseIds is empty, move on.
  if (!purchaseIds.length) {
    return <Redirect to={redemptionsUrl} />;
  }

  return <NonEmptyBulkRedeem purchaseIds={purchaseIds} clearStore={() => setPurchaseIds([])} />;
};

export default PurchaseBulkRedeemContainer;
