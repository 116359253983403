import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useBulkRedemptionStore, usePurchasesStore, useStoreListingStore, useUserStore } from '../state/store';
import { AppContext, RepositoryContext } from './contexts';
import { getGenericLoadingMessage } from './l18n';
import { Purchase, Redemption } from './types';
import { getAbsoluteStoreUrl, getPurchaseRedeemUrl, purchaseBulkRedeemUrl } from './urls';

export const useContributionEntry = (itemId: string, userId: string) => {
  const repo = useRepository();
  return useQuery(['contribution-entry', itemId, userId], () => {
    return repo.getContributionEntry(itemId, userId);
  });
};

// Use a generic loading message.
export const useGenericLoadingMessage = (id?: number | string) => {
  const [message, setMessage] = useState<[string, string] | null>(null);

  // Pick a message on mount.
  useEffect(() => {
    setMessage(getGenericLoadingMessage());
  }, [id]);

  return message;
};

/**
 * Redemption activator.
 */
export const useRedemptionActivator = () => {
  const setPurchaseIds = useBulkRedemptionStore((state) => state.setPurchaseIds);
  const history = useHistory();

  const handleRedeem = (purchases: Purchase[] | string[]) => {
    if (!purchases.length || !purchases[0]) return;
    const purchaseIds = purchases.map((p: Purchase | string) => (typeof p === 'object' ? p.id : p));

    if (purchases.length === 1) {
      return history.push(getPurchaseRedeemUrl(purchaseIds[0]));
    }

    setPurchaseIds(purchaseIds);
    return history.push(purchaseBulkRedeemUrl);
  };

  return {
    redeem: handleRedeem,
  };
};

export const useDownloadRedemptionMutation = (redemption: Redemption) => {
  const repo = useRepository();
  const queryClient = useQueryClient();
  const loadPurchases = usePurchasesStore((state) => state.loadPurchases);
  return useMutation(
    async () => {
      return repo.getRedemptionFile(redemption.id);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['purchases', redemption.purchase_id], { refetchActive: false });
        queryClient.invalidateQueries(['redemptions', redemption.id], { refetchActive: false });
        loadPurchases(repo);
      },
    }
  );
};

export const useIsEmbedded = () => {
  const { embedded } = useContext(AppContext);
  return embedded;
};

export const useMakeContributionMutation = (itemId: string, userId: string) => {
  const repo = useRepository();
  const loadBalance = useUserStore((state) => state.loadBalance);
  const loadProducts = useStoreListingStore((state) => state.loadProducts);
  const queryClient = useQueryClient();
  return useMutation(
    (amount: number) => {
      return repo.makeContribution(itemId, amount);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['contribution-entry', itemId, userId], data);

        // TODO These should eventually be moved to react-query.
        loadBalance(repo);
        loadProducts(repo);
      },
      onError: (err: any) => {
        if (err?.code === 'INSUFFICIENT_FUNDS') {
          loadBalance(repo);
        }
      },
    }
  );
};

export const useMetaRedemption = (purchaseIds: string[], options?: UseQueryOptions<{ url: string; redemptions: Redemption[] }>) => {
  const repo = useRepository();
  return useQuery(
    ['meta-redemption', ...purchaseIds.sort()],
    async () => repo.getMetaRedemption(purchaseIds, getAbsoluteStoreUrl()),
    options
  );
};

export const usePurchase = (purchaseId: string, options?: UseQueryOptions<Purchase>) => {
  const repo = useRepository();
  return useQuery(['purchases', purchaseId], async () => repo.getPurchase(purchaseId), options);
};

export const useRedemption = (purchaseId: string, options?: UseQueryOptions<Redemption>) => {
  const repo = useRepository();
  return useQuery(['redemptions', purchaseId], async () => repo.getRedemption(purchaseId, getAbsoluteStoreUrl()), options);
};

export const useSubmitMetaRedemptionMutation = (redemptions: Redemption[]) => {
  const repo = useRepository();
  return useMutation((data: any) => {
    const ids = redemptions.map((r) => r.id);
    return repo.submitMetaRedemption(ids, data);
  });
};

export const useSubmitRedemptionMutation = (redemption: Redemption) => {
  const repo = useRepository();
  const queryClient = useQueryClient();
  const loadPurchases = usePurchasesStore((state) => state.loadPurchases);
  return useMutation(
    (data: any) => {
      return repo.submitRedemption(redemption.id, data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['purchases', redemption.purchase_id], { refetchActive: false });
        queryClient.invalidateQueries(['redemptions', redemption.id], { refetchActive: false });
        loadPurchases(repo);
      },
    }
  );
};

// Force a state refresh even n seconds.
export const useRefresher = (intervalSecs = 30) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const t = setTimeout(() => {
      setCount(count > 9999999 ? 0 : count + 1);
    }, intervalSecs * 1000);
    return () => clearTimeout(t);
  });
};

export const useRepository = () => {
  return useContext(RepositoryContext);
};
