import { Item, ItemType } from './types';

export enum LeaderboardFilter {
  Solo = 'solo',
  Team = 'team',
}

export enum StoreFilter {
  All = 0,
  BuyNow = 1,
  Auction = 2,
  Raffle = 3,
  Contribution = 4,
}

export enum StoreOrder {
  EndingSoon = 0,
  Availability = 1,
}

export enum RedemptionFilter {
  Pending = 'pending',
  Completed = 'completed',
}

// Shared properties of a product.
type ProductSharedProperties = {
  id: string; // This is either the item ID, or the product ID.
  type: ItemType;
  available_from: number;
  available_until: number;
  cost: number;
  description: string;
  name: string;
  quantity: number;
  remaining_stock: number;
  image_url: string;
  thumbnail_url: string;
  item: null;
  variants: null;
};

export type ProductWithoutVariants = Omit<ProductSharedProperties, 'item'> & {
  item: Item;
};

export type ProductWithVariants = Omit<ProductSharedProperties, 'variants'> & {
  variants: {
    id: string;
    name: string;
    remaining_stock: number;
    item: Item;
  }[];
};

// A product is a local type that inclues most properties of a type Item,
// but its structure is different as it represents a store listing more than
// the item being purchased. When the product has variants, they are listed
// in the variants array. When they do not have variants, the item is found
// in the item property.
export type Product = ProductWithVariants | ProductWithoutVariants;

export type CartItem = {
  id: string;
  cost: number;
  description: string;
  name: string;
  quantity: number;
  thumbnail_url: string;
};

export type Toast = {
  id: string;
  message: string;
};

export type RecentActivityItem = {
  date: Date;
  amount: number;
  description: string;
};
