import React from 'react';
import EmptyStateImg from '../assets/empty-state.svg';
import FrownImg from '../assets/frown.svg';
import { now } from '../lib/date';
import { useRefresher } from '../lib/hooks';
import { getChanceOfWinning, hasUnlimitedStock, isAvailable } from '../lib/item';
import { getChanceOfWinningSentence, getTypeName, getTypeNameFromFilter, timeLeft, _ } from '../lib/l18n';
import { ItemType } from '../lib/types';
import { Product, StoreFilter, StoreOrder } from '../lib/types.local';
import { getProductUrl } from '../lib/urls';
import ItemTile from './ItemTile';
import ItemTileList from './ItemTileList';
import ContentContainer from './layouts/ContentContainer';
import PillMenu, { PillMenuItem } from './PillMenu';

const isEndingSoon = (item: Product) => {
  return item.available_until <= now() + 5 * 86400;
};

const isStockLow = (item: Product) => {
  return item.type === ItemType.Purchase && (item.remaining_stock <= 5 || item.remaining_stock < item.quantity * 0.1);
};

const isSoldout = (item: Product) => {
  return item.type === ItemType.Purchase && !hasUnlimitedStock(item) && item.remaining_stock <= 0;
};

const Store: React.FC<{
  currentUserId: string;
  products: Product[];
  availableProductTypes: ItemType[];
  filter?: StoreFilter;
  order?: StoreOrder;
  onFilterChange: (filter: StoreFilter) => void;
  onOrderChange: (order: StoreOrder) => void;
}> = ({
  currentUserId,
  products,
  availableProductTypes,
  filter = StoreFilter.All,
  order = StoreOrder.EndingSoon,
  onFilterChange,
  onOrderChange,
}) => {
  // Refresh every now and then to update the time left, and items no longer available.
  useRefresher();

  let displayProducts = products;

  if (filter !== StoreFilter.All) {
    displayProducts = displayProducts.filter((i) => {
      if (filter === StoreFilter.BuyNow) return i.type === ItemType.Purchase;
      if (filter === StoreFilter.Raffle) return i.type === ItemType.Raffle;
      if (filter === StoreFilter.Auction) return i.type === ItemType.Auction;
      if (filter === StoreFilter.Contribution) return i.type === ItemType.Contribution;
      return false;
    });
  }

  displayProducts = displayProducts
    .sort((i1, i2) => {
      if (!i1.remaining_stock || !i2.remaining_stock) return -(i1.remaining_stock - i2.remaining_stock); // Sold out always last.
      if (order === StoreOrder.EndingSoon) return i1.available_until - i2.available_until;
      if (order === StoreOrder.Availability) return i1.remaining_stock - i2.remaining_stock;
      return 0;
    })
    .filter(isAvailable);

  return (
    <ContentContainer className="flex flex-col flex-grow">
      {availableProductTypes.length > 1 ? (
        <PillMenu>
          <PillMenuItem onClick={() => onFilterChange(StoreFilter.All)} active={filter === StoreFilter.All}>
            {_('allItems')}
          </PillMenuItem>
          {availableProductTypes.includes(ItemType.Purchase) ? (
            <PillMenuItem onClick={() => onFilterChange(StoreFilter.BuyNow)} active={filter === StoreFilter.BuyNow}>
              {_('typeBuyNow')}
            </PillMenuItem>
          ) : null}
          {availableProductTypes.includes(ItemType.Auction) ? (
            <PillMenuItem onClick={() => onFilterChange(StoreFilter.Auction)} active={filter === StoreFilter.Auction}>
              {_('typeAuction')}
            </PillMenuItem>
          ) : null}
          {availableProductTypes.includes(ItemType.Raffle) ? (
            <PillMenuItem onClick={() => onFilterChange(StoreFilter.Raffle)} active={filter === StoreFilter.Raffle}>
              {_('typeRaffle')}
            </PillMenuItem>
          ) : null}
          {availableProductTypes.includes(ItemType.Contribution) ? (
            <PillMenuItem onClick={() => onFilterChange(StoreFilter.Contribution)} active={filter === StoreFilter.Contribution}>
              {_('item.type.contribution')}
            </PillMenuItem>
          ) : null}
        </PillMenu>
      ) : null}
      <StoreContent
        currentUserId={currentUserId}
        products={displayProducts}
        filter={filter}
        order={order}
        onOrderChange={onOrderChange}
      />
    </ContentContainer>
  );
};

const StoreContent: React.FC<{
  currentUserId: string;
  products: Product[];
  order: StoreOrder;
  filter: StoreFilter;
  onOrderChange: (order: StoreOrder) => void;
}> = ({ currentUserId: userId, products, filter, order, onOrderChange }) => {
  if (!products.length) {
    return <StoreContentEmpty filter={filter} />;
  }

  const orderChangeHandler = (order: StoreOrder) => {
    return (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onOrderChange(order);
    };
  };

  return (
    <>
      <div className="mb-4 mt-6">
        {_('sortByColon')}{' '}
        <a
          href="#endingsoon"
          onClick={orderChangeHandler(StoreOrder.EndingSoon)}
          className={`${order === StoreOrder.EndingSoon ? 'underline' : ''}`}
        >
          {_('endingSoon')}
        </a>{' '}
        |{' '}
        <a
          href="#availability"
          onClick={orderChangeHandler(StoreOrder.Availability)}
          className={`${order === StoreOrder.Availability ? 'underline' : ''}`}
        >
          {_('availability')}
        </a>
      </div>
      <ItemTileList>
        {products.map((i) => {
          const isContribution = i.type === ItemType.Contribution;
          let goal;
          let goalProgress;
          let cost = i.cost;
          let costSuffix;
          let tag;

          if (isSoldout(i)) {
            tag = _('soldOutExcl');
          } else if (isEndingSoon(i)) {
            tag = timeLeft(new Date(i.available_until * 1000));
          } else if (isStockLow(i)) {
            tag = _('onlyXLeft', { quantity: i.remaining_stock });
          }

          if (isContribution) {
            const contributed = i.item?.contribution?.coins || 0;
            goal = i.item?.contribution?.goal || 0;
            cost = goal;
            costSuffix = _('contribution.goal');
            goalProgress = goal ? contributed / goal : 0;
            if (goalProgress >= 1) {
              tag = _('contribution.goalMet');
            }
          }

          let info;
          let highlightInfo = i.type === ItemType.Auction;
          if (i.type === ItemType.Auction && i.item?.auction?.bidder === userId) {
            info = _('youHaveWinningBid');
          } else if (i.type === ItemType.Raffle && i.item) {
            const chanceOfWinning = getChanceOfWinning(i.item, userId);
            if (chanceOfWinning > 0) {
              info = getChanceOfWinningSentence(chanceOfWinning);
            }
          }

          return (
            <ItemTile
              key={i.id}
              itemUrl={getProductUrl(i.id)}
              type={getTypeName(i.type)}
              name={i.name}
              cost={cost}
              costSuffix={costSuffix}
              goal={goal}
              goalProgress={goalProgress}
              tag={tag}
              image={i.thumbnail_url}
              info={info}
              highlightInfo={highlightInfo}
            />
          );
        })}
      </ItemTileList>
    </>
  );
};

const StoreContentEmpty: React.FC<{ filter: StoreFilter }> = ({ filter }) => {
  const title =
    filter === StoreFilter.All ? _('noProductsYet') : _('noProductTypeAvailable', { itemtype: getTypeNameFromFilter(filter) });
  const imgSrc = filter === StoreFilter.All ? EmptyStateImg : FrownImg;
  const maxHeight = filter === StoreFilter.All ? 306 : 144;
  const message = filter === StoreFilter.All ? _('stayTunedForShop') : _('checkOtherTypesOfItems');

  return (
    <div className="flex md:flex-grow justify-center items-center py-6">
      <div className="py-8 lg:w-4/5 px-4 justify-center flex flex-col text-center bg-grey-light rounded-lg">
        <img src={imgSrc} alt="" style={{ maxHeight: `${maxHeight}px` }} />
        <h1 className="mt-8 mb-4 md:text-3xl text-2xl">{title}</h1>
        <div className="text-grey-steel text-xl">{message}</div>
      </div>
    </div>
  );
};

export default Store;
