import React from 'react';
import nl2br from 'react-nl2br';
import ContentContainer from './layouts/ContentContainer';

const Information: React.FC<{
  loading: boolean;
  type: string;
  content: string;
}> = ({ loading, type, content }) => {
  if (type !== 'text/plain') {
    return null;
  } else if (loading) {
    return null;
  }
  return (
    <ContentContainer className="flex">
      <p>{nl2br(content)}</p>
    </ContentContainer>
  );
};

export default Information;
