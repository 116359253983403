import React from 'react';
import Store from '../components/Store';
import { useStoreListingStore, useUserStore } from '../state/store';

const StoreContainer = () => {
  const state = useStoreListingStore();
  const userStore = useUserStore();
  return (
    <Store
      products={state.products}
      availableProductTypes={state.product_types}
      order={state.order}
      filter={state.filter}
      onFilterChange={state.setFilter}
      onOrderChange={state.setOrder}
      currentUserId={userStore.id}
    />
  );
};

export default StoreContainer;
