import React from 'react';

export const PillMenuItem: React.FC<{ active?: boolean; onClick: () => void }> = ({ active, children, onClick }) => {
  let classes =
    'py-2 px-4 border block border-silver first:rounded-t border-b-0 last:border-b last:rounded-b hover:no-underline text-grey md:border-b md:border-l-0 md:first:border-l md:first:rounded-l md:first:rounded-r-none md:last:border-r md:last:rounded-r md:last:rounded-l-none md:only:rounded';
  if (active) {
    classes += ' bg-grey-light';
  }
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick();
  };
  return (
    <a href="#p" className={classes} onClick={handleClick}>
      {children}
    </a>
  );
};

const PillMenu: React.FC = ({ children }) => {
  return (
    <div className="md:flex md:justify-end">
      <div className="flex flex-col md:flex-row">{children}</div>
    </div>
  );
};

export default PillMenu;
