import React, { useState, useEffect } from 'react';
import { useUserStore, useCartStore, usePurchasesStore, useBrandingStore } from '../state/store';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';

const HeaderContainer = () => {
  const [opened, setOpened] = useState(false);
  const balance = useUserStore((state) => state.balance);
  const { logo } = useBrandingStore((s) => s.branding);
  const numItemsInCart = useCartStore((state) => state.items.length);
  const purchasesStore = usePurchasesStore(); // Using the selector only does not trigger state updates.
  const location = useLocation();

  const [openedDropdown, setOpenedDropdown] = useState<[string?, React.RefObject<HTMLInputElement>?]>([]);

  // Close the menu and dropdowns when we click a link.
  useEffect(() => {
    setOpened(false);
    setOpenedDropdown([]);
  }, [location]);

  // Close the dropdowns when clicking outside.
  useEffect(() => {
    if (!openedDropdown[1] || !openedDropdown[1].current) return;
    const fn = (e: MouseEvent) => {
      const el = e.target;
      if (el instanceof Node && openedDropdown[1]?.current && openedDropdown[1].current?.contains(el)) return;
      setOpenedDropdown([]);
    };
    document.body.addEventListener('click', fn);
    return () => {
      document.body.removeEventListener('click', fn);
    };
  });

  const handleDropdownToggle = (id: string, ref: React.RefObject<HTMLInputElement>) => {
    if (openedDropdown[0] === id) {
      setOpenedDropdown([]);
    } else {
      setOpenedDropdown([id, ref]);
    }
  };

  const newRewards = purchasesStore.countNewPurchases();

  return (
    <Header
      balance={balance}
      logo={logo}
      newRewards={newRewards}
      opened={opened}
      onOpenChange={() => setOpened(!opened)}
      numItemsInCart={numItemsInCart}
      onDropdownToggle={handleDropdownToggle}
      openedDropdown={openedDropdown[0]}
    />
  );
};

export default HeaderContainer;
