import create from 'zustand';
import { DummyRepository, Repository } from '../lib/repository';
import { Branding, Item, ItemType, Purchase, PurchaseState, UserTransaction } from '../lib/types';
import { CartItem, Product, StoreFilter, StoreOrder, Toast } from '../lib/types.local';

const [useUserStore] = create<{
  id: string;
  accountId: string;
  sectionId: string;
  balance: number;
  loadBalance: (repository: Repository) => Promise<void>;
  loadUser: (repository: Repository) => Promise<void>;
  logout: () => Promise<void>;
}>((set) => ({
  id: '',
  accountId: '',
  sectionId: '',
  balance: 0,
  loadBalance: async (repository: Repository) => {
    set({ balance: await repository.getBalance() });
  },
  loadUser: async (repository: Repository) => {
    const user = repository.getMe();
    const balance = repository.getBalance();
    const userData = await user;
    set({ id: userData.id, accountId: userData.account_id, sectionId: userData.school_id, balance: await balance });
  },
  logout: async () => {
    set({ id: '', sectionId: '', accountId: '', balance: 0 });
  },
}));

const [useAlertStore] = create<{
  toasts: Toast[];
  addMessageAsToast: (message: string) => void;
  removeToast: (toast: Toast) => void;
}>((set) => ({
  toasts: [],
  addMessageAsToast: (message: string) => {
    set((state) => {
      const toast = { id: Date.now().toString(), message };
      setTimeout(() => {
        state.removeToast(toast);
      }, 10000);
      return { toasts: state.toasts.concat([toast]) };
    });
  },
  removeToast: (toast) => {
    set((state) => ({ toasts: state.toasts.filter((t) => t !== toast) }));
  },
}));

const [useBrandingStore] = create<{
  branding: Branding;
  loadBranding: (repository: Repository) => void;
  loading: boolean;
  loaded: boolean;
}>((set) => ({
  branding: { icon_double: null, logo: null },
  loadBranding: async (repository: Repository) => {
    set({ loading: true });
    const branding = await repository.getBranding();
    set({ branding, loading: false, loaded: true });
  },
  loading: false,
  loaded: false,
}));

const [useBulkRedemptionStore] = create<{
  purchaseIds: string[];
  setPurchaseIds: (ids: string[]) => void;
}>((set, get) => ({
  purchaseIds: [],
  setPurchaseIds: (purchaseIds: string[]) => set({ purchaseIds }),
}));

const [useLeaderboardStore] = create<{
  loaded: boolean;
  loading: boolean;
  anyEnabled: boolean;
  leaderboardEnabled: boolean;
  svsLeaderboardEnabled: boolean;
  loadLeaderboardEnabled: (repository: Repository) => void;
}>((set, get) => ({
  loaded: false,
  loading: false,
  anyEnabled: false,
  leaderboardEnabled: false,
  svsLeaderboardEnabled: false,
  loadLeaderboardEnabled: async (repository: Repository) => {
    set({ loading: true });

    const leaderboardEnabled = await repository.isLeaderboardEnabled();
    set({ leaderboardEnabled, anyEnabled: leaderboardEnabled });

    const svsLeaderboardEnabled = await repository.isSvsLeaderboardEnabled();
    set({ svsLeaderboardEnabled, loaded: true, loading: false, anyEnabled: leaderboardEnabled || svsLeaderboardEnabled });
  },
}));

const [useLoadingOverlay] = create<{
  id: number;
  opened: boolean;
  setOpened: (opened: boolean) => void;
}>((set, get) => ({
  id: 0,
  opened: false,
  setOpened: (opened) => set({ opened, id: get().id + (opened ? 1 : 0) }),
}));

const [usePurchasesStore] = create<{
  purchases: Purchase[];
  countNewPurchases: () => number;
  last_seen: Date;
  loading: boolean;
  loadPurchases: (repository: Repository) => void;
  setPurchases: (purchases: Purchase[]) => void;
  updateLastSeen: (repository: Repository) => void;
}>((set, get) => ({
  purchases: [],
  countNewPurchases: () => {
    const { last_seen, purchases } = get();
    const ts = Math.floor(last_seen.getTime() / 1000);
    return purchases.filter((p) => p.state === PurchaseState.Made && p.made_on > ts).length;
  },
  last_seen: new Date(0),
  loading: false,
  loadPurchases: async (repository: Repository) => {
    set({ loading: true });
    set({ loading: false, purchases: await repository.getPurchases() });
    set({ last_seen: await repository.getLastSeenPurchases() });
  },
  setPurchases: (purchases: Purchase[]) => {
    set({ purchases });
  },
  updateLastSeen: async (repository: Repository) => {
    const now = new Date();
    set({ last_seen: now });
    await repository.setLastSeenPurchases(now);
  },
}));

const [useRecentActivityStore] = create<{
  items: UserTransaction[];
  loading: boolean;
  loadItems: (repository: Repository) => void;
  setItems: (items: UserTransaction[]) => void;
}>((set) => ({
  items: [],
  loading: false,
  loadItems: async (repository: Repository) => {
    set({ loading: true });
    set({ loading: false, items: await repository.getRecentActivity() });
  },
  setItems: (items: UserTransaction[]) => {
    set({ items });
  },
}));

const [useRepository] = create<{
  loaded: boolean;
  repository: Repository;
  setRepository: (repository: Repository) => void;
}>((set) => ({
  loaded: false,
  repository: new DummyRepository(),
  setRepository: (repository: Repository) => set({ repository, loaded: true }),
}));

const [useStoreListingStore] = create<{
  loaded: boolean;
  loading: boolean;
  products: Product[];
  product_types: ItemType[]; // The product types seen in the products retrieved.
  filter: StoreFilter;
  order: StoreOrder;
  loadProducts: (repository: Repository) => Promise<void>;
  setFilter: (filter: StoreFilter) => void;
  setOrder: (order: StoreOrder) => void;
  setProducts: (products: Product[]) => void;
}>((set) => ({
  loaded: false,
  loading: false,
  products: [],
  product_types: [],
  filter: StoreFilter.All,
  order: StoreOrder.EndingSoon,
  loadProducts: async (repository: Repository) => {
    set({ loading: true });
    try {
      const products = await repository.getProducts();
      const types = products.reduce<ItemType[]>((carry, product) => {
        if (!carry.includes(product.type)) {
          return [...carry, product.type];
        }
        return carry;
      }, []);
      set({ products, product_types: types, loaded: true });
    } catch {}
    set({ loading: false });
  },
  setFilter: (filter: StoreFilter) => set({ filter }),
  setOrder: (order: StoreOrder) => set({ order }),
  setProducts: (products: Product[]) => set({ products }),
}));

const [useCartStore] = create<{
  empty: () => void;
  getQuantityOf: (itemId: string) => number;
  items: CartItem[];
  increaseQuantity: (item: Item, quantity: number) => void;
  remove: (itemId: string) => void;
  setQuantity: (itemId: string, quantity: number) => void;
}>((set, get) => ({
  empty: () => set({ items: [] }),
  getQuantityOf: (itemId: string) => {
    return get().items.find((i) => i.id === itemId)?.quantity || 0;
  },
  items: [],
  increaseQuantity: (item: Item, quantity: number) =>
    set((state) => {
      if (item.type !== ItemType.Purchase) return state;

      let found = Boolean(state.items.find((i) => i.id === item.id));
      let newItems;

      if (!found) {
        newItems = state.items.concat([
          {
            id: item.id,
            name: item.name,
            cost: item.cost,
            quantity: Math.min(25, quantity),
            description: item.description,
            thumbnail_url: item.thumbnail_url,
          },
        ]);
      } else {
        newItems = state.items
          .map((i) => {
            if (i.id !== item.id) return i;
            return { ...i, quantity: Math.min(25, i.quantity + quantity) };
          })
          .filter((i) => i.quantity > 0);
      }

      return { items: newItems };
    }),
  setQuantity: (itemId: string, quantity: number) =>
    set((state) => {
      return {
        items: state.items
          .map((i) => {
            if (i.id !== itemId) return i;
            return { ...i, quantity };
          })
          .filter((i) => i.quantity > 0),
      };
    }),
  remove: (itemId: string) => set((state) => ({ items: state.items.filter((i) => i.id !== itemId) })),
}));

export {
  useAlertStore,
  useBrandingStore,
  useBulkRedemptionStore,
  useLeaderboardStore,
  useUserStore,
  useStoreListingStore,
  useCartStore,
  useLoadingOverlay,
  usePurchasesStore,
  useRecentActivityStore,
  useRepository,
};
