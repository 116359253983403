import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorContent from '../components/ErrorContent';
import { ProductAuction, ProductBuyNow, ProductContributionContainer, ProductRaffle } from '../components/Product';
import { useRedemptionActivator } from '../lib/hooks';
import { _ } from '../lib/l18n';
import { Item, ItemType } from '../lib/types';
import { useAlertStore, useCartStore, useLoadingOverlay, useRepository, useStoreListingStore, useUserStore } from '../state/store';

const ProductContainer = () => {
  const { id } = useParams<{ id: string }>();
  const products = useStoreListingStore((state) => state.products);
  const loadProducts = useStoreListingStore((state) => state.loadProducts);
  const cartStore = useCartStore();
  const alertStore = useAlertStore();
  const repository = useRepository(({ repository }) => repository);
  const userStore = useUserStore();
  const setLoadingOverlayOpened = useLoadingOverlay((s) => s.setOpened);
  const [isLocked, setLocked] = useState(false);
  const [error, setError] = useState<string>();
  const { redeem } = useRedemptionActivator();

  let handleAddToCart;
  let handleBuy;
  let handlePlaceBid;
  let handleBuyEntries;

  const handleErrorClose = () => setError(undefined);

  const product = products.find((p) => p.id === id);
  if (!product) {
    return <ErrorContent>{_('itemNotExistsOrUnavailable')}</ErrorContent>;
  }

  switch (product.type) {
    case ItemType.Purchase:
      handleAddToCart = (item: Item, qty: number) => {
        cartStore.increaseQuantity(item, qty);
        alertStore.addMessageAsToast(_('itemAddedToCart'));
      };

      handleBuy = async (item: Item, qty: number) => {
        let purchases = [];
        setLocked(true);
        setLoadingOverlayOpened(true);
        setError(undefined);
        try {
          purchases = await repository.purchaseItem(item.id, qty);
        } catch (err) {
          let error = _('error.unknown');
          if (err.code === 'INVALID_ITEM') {
            error = _('error.itemSoldOut');
            loadProducts(repository);
          } else if (err.code === 'INSUFFICIENT_FUNDS') {
            error = _('error.insufficientFunds');
            userStore.loadBalance(repository);
          } else if (err.code === 'NOT_AVAILABLE') {
            error = _('error.itemNotAvailable');
          }
          setError(error);
          setLocked(false);
          setLoadingOverlayOpened(false);
          return;
        }

        userStore.loadBalance(repository);
        loadProducts(repository); // TODO Let react-query do this!
        setLocked(false);
        setLoadingOverlayOpened(false);
        redeem(purchases);

        // Code used when the redemption was not instant.
        // setLocked(false);
        // alertStore.addMessageAsToast(_('itemPurchased'));
        // userStore.loadBalance(repository);
        // loadProducts(repository);
        // loadPurchases(repository);
      };

      return (
        <ProductBuyNow
          product={product}
          error={error}
          onErrorClose={handleErrorClose}
          onBuy={handleBuy}
          onAddToCart={handleAddToCart}
          locked={isLocked}
        />
      );

    case ItemType.Auction:
      handlePlaceBid = async (item: Item, bid: number) => {
        setLocked(true);
        setLoadingOverlayOpened(true);
        setError(undefined);
        try {
          await repository.placeBid(item.id, bid);
        } catch (err) {
          let error = _('error.unknown');
          if (err.code === 'INVALID_BID') {
            error = _('error.invalidBidPossibleRace');
            loadProducts(repository);
          } else if (err.code === 'INSUFFICIENT_FUNDS') {
            error = _('error.insufficientFunds');
            userStore.loadBalance(repository);
          } else if (err.code === 'NOT_AVAILABLE') {
            error = _('error.itemNotAvailable');
          }
          setError(error);
          setLocked(false);
          setLoadingOverlayOpened(false);
          return;
        }
        setLocked(false);
        setLoadingOverlayOpened(false);
        alertStore.addMessageAsToast(_('bidPlaced'));
        userStore.loadBalance(repository);
        loadProducts(repository);
      };

      return (
        <ProductAuction
          product={product}
          error={error}
          onErrorClose={handleErrorClose}
          onPlaceBid={handlePlaceBid}
          locked={isLocked}
        />
      );

    case ItemType.Raffle:
      handleBuyEntries = async (item: Item, quantity: number) => {
        setLocked(true);
        setLoadingOverlayOpened(true);
        setError(undefined);
        try {
          await repository.purchaseRaffleTicket(item.id, quantity);
        } catch (err) {
          let error = _('error.unknown');
          if (err.code === 'INSUFFICIENT_FUNDS') {
            error = _('error.insufficientFunds');
            userStore.loadBalance(repository);
          } else if (err.code === 'NOT_AVAILABLE') {
            error = _('error.itemNotAvailable');
          }
          setError(error);
          setLocked(false);
          setLoadingOverlayOpened(false);
          return;
        }
        setLocked(false);
        setLoadingOverlayOpened(false);
        alertStore.addMessageAsToast(_('raffle.entriesPurchased'));
        userStore.loadBalance(repository);
        loadProducts(repository);
      };

      return (
        <ProductRaffle product={product} error={error} onErrorClose={handleErrorClose} onBuy={handleBuyEntries} locked={isLocked} />
      );

    case ItemType.Contribution:
      return <ProductContributionContainer product={product} />;
  }
  return <ErrorContent>{_('Invalid or unknown product type.')}</ErrorContent>;
};

export default ProductContainer;
