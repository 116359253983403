import React from 'react';
import { _ } from '../lib/l18n';
import motrainLogoSrc from '../assets/motrain-logo.svg';

const PoweredBy: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={`flex flex-col items-center text-center text-xs text-grey-steel ${className || ''}`}>
      <div className="mb-1">{_('poweredBy')}</div>
      <a href="https://motrain.com" target="_blank" rel="noopener noreferrer">
        <img src={motrainLogoSrc} alt="Motrain" style={{ height: '0.75rem' }} />
      </a>
    </div>
  );
};

export default PoweredBy;
