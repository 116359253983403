import React from 'react';
import { UseQueryResult } from 'react-query';
import { Redirect, useParams } from 'react-router-dom';
import ContentContainer from '../components/layouts/ContentContainer';
import { NotificationError } from '../components/Notification';
import DownloadRedeemForm from '../components/redeem/DownloadRedeemForm';
import ExternalRedeemRedirect from '../components/redeem/ExternalRedeemRedirect';
import RequestRedeemForm from '../components/redeem/RequestRedeemForm';
import SelfRedeemForm from '../components/redeem/SelfRedeemForm';
import ShippingRedeemForm from '../components/redeem/ShippingRedeemForm';
import Spinner from '../components/Spinner';
import { useDownloadRedemptionMutation, usePurchase, useRedemption, useSubmitRedemptionMutation } from '../lib/hooks';
import { _ } from '../lib/l18n';
import { Purchase, PurchaseState, Redemption, RedemptionState } from '../lib/types';
import { getPurchaseThankYouUrl } from '../lib/urls';

const PurchaseRedeem: React.FC<{
  id: string;
  purchaseQuery: UseQueryResult<Purchase>;
  redemptionQuery: UseQueryResult<Redemption>;
}> = ({ id, purchaseQuery, redemptionQuery }) => {
  const { isFetching, isError } = purchaseQuery;
  const purchase = purchaseQuery?.data;
  const redemption = redemptionQuery?.data;
  const isValidState = purchase?.state === PurchaseState.Made;
  const anyFetching = isFetching || redemptionQuery.isFetching;

  if (anyFetching) {
    return (
      <ContentContainer className="flex justify-center">
        <Spinner />
      </ContentContainer>
    );
  } else if (isError) {
    return (
      <ContentContainer>
        <NotificationError showClose={false}>{_('purchaseNotFound')}</NotificationError>
      </ContentContainer>
    );
  } else if (!isValidState) {
    return (
      <ContentContainer>
        <NotificationError showClose={false}>{_('purchaseAlreadyRedeemed')}</NotificationError>
      </ContentContainer>
    );
  } else if (redemptionQuery.data?.state !== RedemptionState.Idle) {
    return (
      <ContentContainer>
        <NotificationError showClose={false}>{_('redemptionAlreadyProcessed')}</NotificationError>
      </ContentContainer>
    );
  } else if (redemption?.options?.url_only && redemption.url) {
    return (
      <ContentContainer className="flex justify-center">
        <ExternalRedeemRedirect url={redemption.url} />
        <Spinner />
      </ContentContainer>
    );
  }

  let title = _('redemption:request.title');
  let subtitle = _('redemption:request.subtitle');
  let footnote;
  if (redemption && redemption.type === '_download') {
    title = _('redemption:download.title');
    subtitle = _('redemption:download.subtitle');
  }

  if (redemption?.options?.subtitle) {
    subtitle = redemption.options.subtitle;
  }
  if (redemption?.options?.footnote) {
    footnote = redemption?.options?.footnote;
  }

  return (
    <ContentContainer className="flex flex-col items-center">
      <div className="max-w-md">
        <h1 className="text-xl mb-2 text-center">{title}</h1>
        <h2 className="text-lg mb-8 text-center">{subtitle}</h2>
        {purchase ? <PurchaseItemShowcase purchase={purchase} /> : null}
      </div>
      {redemption ? <RedemptionForm redemption={redemption} /> : null}
      <div className="max-w-md w-full">{footnote ? <p className="mt-4">{footnote}</p> : null}</div>
    </ContentContainer>
  );
};

const PurchaseItemShowcase: React.FC<{ purchase: Purchase }> = ({ purchase }) => {
  return (
    <div className="flex flex-col items-center my-4">
      <p className="mb-2">{purchase.item.name}</p>
      <div className="h-24 w-24">
        <img src={purchase.item.image_url} alt="" className="w-full h-full" />
      </div>
    </div>
  );
};

const RedemptionForm: React.FC<{ redemption: Redemption }> = ({ redemption }) => {
  if (redemption.type === '_self') {
    return <RedemptionSelfForm redemption={redemption} />;
  } else if (redemption.type === '_request') {
    return <RedemptionRequestForm redemption={redemption} />;
  } else if (redemption.type === '_download') {
    return <RedemptionDownloadForm redemption={redemption} />;
  } else if (redemption.type === '_shipping') {
    return <RedemptionShippingForm redemption={redemption} />;
  }
  return <>Unknown type</>;
};

const RedemptionDownloadForm: React.FC<{ redemption: Redemption }> = ({ redemption }) => {
  const mutation = useDownloadRedemptionMutation(redemption);
  return (
    <div className="max-w-md">
      {mutation.isSuccess ? <iframe src={mutation.data?.url} title="file download" className="sr-only" /> : null}
      <DownloadRedeemForm redemptions={[redemption]} onSubmit={() => mutation.mutate()} isLoading={mutation.isLoading} />
    </div>
  );
};

const RedemptionRequestForm: React.FC<{ redemption: Redemption }> = ({ redemption }) => {
  const mutation = useSubmitRedemptionMutation(redemption);
  return (
    <div className="max-w-md">
      {mutation.isSuccess ? <Redirect to={getPurchaseThankYouUrl(redemption.purchase_id)} /> : null}
      {mutation.isError ? (
        <div className="mb-8">
          <NotificationError showClose={false}>{_('redemption:error.occurredWhileProcessing')}</NotificationError>
        </div>
      ) : null}
      <RequestRedeemForm
        redemptions={[redemption]}
        onSubmit={(data: any) => mutation.mutate(data)}
        isLoading={mutation.isLoading}
      />
    </div>
  );
};

const RedemptionSelfForm: React.FC<{ redemption: Redemption }> = ({ redemption }) => {
  const mutation = useSubmitRedemptionMutation(redemption);
  return (
    <div className="max-w-md">
      {mutation.isSuccess ? <Redirect to={getPurchaseThankYouUrl(redemption.purchase_id)} /> : null}
      {mutation.isError ? (
        <div className="mb-8">
          <NotificationError showClose={false}>{_('redemption:error.occurredWhileProcessing')}</NotificationError>
        </div>
      ) : null}
      <SelfRedeemForm onSubmit={() => mutation.mutate({})} isLoading={mutation.isLoading} redemptions={[redemption]} />
    </div>
  );
};

const RedemptionShippingForm: React.FC<{ redemption: Redemption }> = ({ redemption }) => {
  const mutation = useSubmitRedemptionMutation(redemption);
  return (
    <div className="max-w-lg w-full">
      {mutation.isSuccess ? <Redirect to={getPurchaseThankYouUrl(redemption.purchase_id)} /> : null}
      {mutation.isError ? (
        <div className="mb-8">
          <NotificationError showClose={false}>{_('redemption:error.occurredWhileProcessing')}</NotificationError>
        </div>
      ) : null}
      <ShippingRedeemForm redemptions={[redemption]} onSubmit={(data) => mutation.mutate(data)} isLoading={mutation.isLoading} />
    </div>
  );
};

const PurchaseRedeemContainer = () => {
  const params = useParams<{ id: string }>();
  const query = usePurchase(params.id, { staleTime: 0, refetchOnWindowFocus: false });
  const redemptionQuery = useRedemption(params.id, {
    refetchOnWindowFocus: false,
    enabled: query.isSuccess && query?.data.state === PurchaseState.Made,
  });

  return <PurchaseRedeem id={params.id} purchaseQuery={query} redemptionQuery={redemptionQuery} />;
};

export default PurchaseRedeemContainer;
