import React, { useEffect, useState } from 'react';
import { UseMutationResult, UseQueryResult } from 'react-query';
import { useContributionEntry, useMakeContributionMutation } from '../lib/hooks';
import {
  getChanceOfWinning,
  getHighestBid,
  getItemFromProduct,
  getMinBid,
  getMyEntries,
  getNumberOfBids,
  getRemainingStock,
  hasUnlimitedStock,
  isAvailable,
} from '../lib/item';
import { getChanceOfWinningSentence, getTypeName, _ } from '../lib/l18n';
import { ContributionEntry, Item as ItemTypeDef, ItemContributionData } from '../lib/types';
import { Product as ProductType, ProductWithoutVariants } from '../lib/types.local';
import { useAlertStore, useCartStore, useLoadingOverlay, useUserStore } from '../state/store';
import Coins from './Coins';
import ConfirmModal from './ConfirmModal';
import Item from './Item';
import { AuctionForm, BuyNowForm, ContributionForm, RaffleForm } from './ItemForm';
import ContentContainer from './layouts/ContentContainer';
import Select from './Select';

const VariantSelector: React.FC<{
  product: ProductType;
  selected?: string | null;
  onChange: (itemId?: string) => void;
  disabled?: boolean;
}> = ({ product, onChange, disabled, selected }) => {
  if (!product.variants) return null;
  const options = product.variants.map<{ value: string; label: string; disabled: boolean }>((v) => ({
    value: v.id,
    label: `${v.name}${hasUnlimitedStock(v.item) ? '' : ` (${v.remaining_stock})`}`,
    disabled: !hasUnlimitedStock(v.item) && v.remaining_stock <= 0,
  }));
  const currentValue = selected === null ? null : options.find((o) => o.value === selected);
  return (
    <div className="flex mb-6" style={{ maxWidth: '150px' }}>
      <Select
        isDisabled={disabled}
        value={currentValue}
        options={options}
        onChange={(v) => {
          if (!v) onChange();
          onChange(v ? (v as { value: any }).value : null);
        }}
      />
    </div>
  );
};

type ErrorProps = {
  error?: string;
  onErrorClose?: () => void;
};

function makeErrorFromMutation(mutation: UseMutationResult<any, any, any, any>) {
  if (!mutation.isError) {
    return;
  }
  const err = mutation.error;
  let error = _('error.unknown');
  if (err.code === 'INSUFFICIENT_FUNDS') {
    error = _('error.insufficientFunds');
  } else if (err.code === 'NOT_AVAILABLE') {
    error = _('error.itemNotAvailable');
  }
  return error;
}

export const ProductBuyNow: React.FC<
  {
    product: ProductType;
    locked: boolean;
    onBuy: (item: ItemTypeDef, quantity: number) => void;
    onAddToCart: (item: ItemTypeDef, quantity: number) => void;
  } & ErrorProps
> = ({ product, locked, onBuy, onAddToCart, error, onErrorClose }) => {
  const { balance } = useUserStore();
  const getCartQuantityOf = useCartStore((s) => s.getQuantityOf);
  const [quantity, setQuantity] = useState(1);
  const [confirmBuy, setConfirmBuy] = useState(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const hasVariants = Boolean(product.variants);
  const defaultItemId = hasVariants ? null : (product as ProductWithoutVariants).item.id;

  let item: ItemTypeDef | null = null;
  if (itemId) {
    item = getItemFromProduct(product, itemId);
  }

  // Reset the itemId when the product changes.
  useEffect(() => {
    setItemId(defaultItemId);
  }, [product.id]); // eslint-disable-line

  // Reset the quantity when the variant changes.
  useEffect(() => setQuantity(1), [itemId]);

  const cost = product.cost;
  const totalCost = quantity * cost;
  const isProductUnlimited = hasUnlimitedStock(product);
  const isVariantUnlimited = item && hasUnlimitedStock(item);
  const itemInStock = item ? getRemainingStock(item) > 0 : false;
  const isSoldout = !isProductUnlimited && product.remaining_stock <= 0;
  const maxQuantity = Math.min(25, !isVariantUnlimited && item ? getRemainingStock(item) : 25);
  const canBuy = !locked && Boolean(item) && itemInStock && isAvailable(product) && balance >= totalCost;
  const canAddToCart = canBuy && item ? getRemainingStock(item) > getCartQuantityOf(item.id) : false;

  const handleAddToCart = () => {
    if (!item) return;
    onAddToCart(item, quantity);
  };

  const handleConfirmBuy = () => {
    setConfirmBuy(true);
  };

  const handleBuy = () => {
    setConfirmBuy(false);
    if (!item) return;
    onBuy(item, quantity);
    setItemId(defaultItemId);
  };

  return (
    <ContentContainer className="mb-4">
      <Item
        type={getTypeName(product.type)}
        name={product.name}
        image={product.image_url}
        note={
          isSoldout
            ? _('soldOut')
            : !hasVariants && !isProductUnlimited
            ? _('quantityRemaining', { quantity: product.remaining_stock })
            : undefined
        }
        description={product.description}
        error={error}
        onErrorClose={onErrorClose}
      >
        {hasVariants ? (
          <VariantSelector product={product} selected={itemId} onChange={(itemId) => setItemId(itemId || null)} disabled={locked} />
        ) : null}
        <BuyNowForm
          canAddToCart={canAddToCart}
          canBuy={canBuy}
          canChangeQuantity={!locked && Boolean(item)}
          cost={(product || item).cost}
          endDate={new Date((product || item).available_until * 1000)}
          quantity={quantity}
          onQuantityChange={(qty) => setQuantity(Math.min(maxQuantity, Math.max(1, qty)))}
          total={totalCost}
          onAddToCart={handleAddToCart}
          onBuy={handleConfirmBuy}
        />
      </Item>
      <ConfirmModal
        opened={confirmBuy}
        title={_('confirmPurchase')}
        confirmLabel={_('yes')}
        onCancel={() => setConfirmBuy(false)}
        onConfirm={handleBuy}
      >
        {_('confirm.buynow', { item: item?.name || product.name })}
      </ConfirmModal>
    </ContentContainer>
  );
};

export const ProductAuction: React.FC<
  {
    product: ProductType;
    locked: boolean;
    onPlaceBid: (item: ItemTypeDef, quantity: number) => void;
  } & ErrorProps
> = ({ product, locked, onPlaceBid, error, onErrorClose }) => {
  const { id: userId, balance } = useUserStore();
  const [bid, setBid] = useState(0);
  const item = getItemFromProduct(product, product.id);

  const auction = item.auction;
  if (!auction) {
    throw new Error('Unexpected product received.');
  }

  const handlingFee = auction.handling_fee;
  const hasWinningBid = auction.bidder === userId;
  const minBid = getMinBid(item);
  const numberOfBids = getNumberOfBids(item);
  const totalCost = bid + handlingFee;
  const requiredFunds = hasWinningBid ? totalCost - (auction.bid || 0) : totalCost;
  const canBid = !locked && isAvailable(item) && balance >= requiredFunds && bid >= minBid;

  // Reset the bid when the product changes.
  useEffect(() => setBid(minBid), [item.id, minBid]);

  return (
    <ContentContainer className="mb-4">
      <Item
        type={getTypeName(item.type)}
        name={product.name}
        image={item.image_url}
        info={hasWinningBid ? _('youHaveWinningBid') : !numberOfBids ? _('noBids') : undefined}
        description={item.description}
        error={error}
        onErrorClose={onErrorClose}
      >
        <AuctionForm
          bid={bid}
          currentBid={getHighestBid(item)}
          canBid={canBid}
          canChangeBid={!locked}
          handlingFee={auction.handling_fee}
          bidCount={numberOfBids}
          minimumBid={minBid}
          total={totalCost}
          endDate={new Date(item.available_until * 1000)}
          onBidChange={(newBid) => setBid(Math.min(999999, Math.max(0, newBid)))}
          onBid={() => onPlaceBid(item, bid)}
        />
      </Item>
    </ContentContainer>
  );
};

export const ProductRaffle: React.FC<
  {
    product: ProductType;
    locked: boolean;
    onBuy: (item: ItemTypeDef, quantity: number) => void;
  } & ErrorProps
> = ({ product, locked, onBuy, error, onErrorClose }) => {
  const { id: userId, balance } = useUserStore();
  const [quantity, setQuantity] = useState(1);
  const [confirmBuy, setConfirmBuy] = useState(false);
  const item = getItemFromProduct(product, product.id);

  // Reset the quantity when the item changes.
  useEffect(() => setQuantity(1), [item.id]);

  const raffle = item.raffle;
  if (!raffle) {
    throw new Error('Unexpected product received.');
  }

  const cost = item.cost;
  const myEntries = getMyEntries(item, userId);
  const chanceOfWinning = getChanceOfWinning(item, userId);
  const totalCost = quantity * item.cost;
  const canBuy = !locked && isAvailable(product) && balance >= totalCost;

  const handleBuy = () => {
    setConfirmBuy(false);
    onBuy(item, quantity);
    setQuantity(1);
  };

  return (
    <ContentContainer className="mb-4">
      <Item
        type={getTypeName(item.type)}
        name={product.name}
        image={item.image_url}
        info={chanceOfWinning ? getChanceOfWinningSentence(chanceOfWinning) : undefined}
        description={item.description}
        error={error}
        onErrorClose={onErrorClose}
      >
        <RaffleForm
          canBuy={canBuy}
          canChangeQuantity={!locked}
          cost={cost}
          numberOfEntries={myEntries}
          numberOfWinners={item.quantity}
          drawDate={new Date(item.available_until * 1000)}
          quantityBuying={quantity}
          onQuantityChange={(qty) => setQuantity(Math.min(999, Math.max(1, qty)))}
          total={totalCost}
          onBuy={() => setConfirmBuy(true)}
        />
      </Item>
      <ConfirmModal
        opened={confirmBuy}
        title={_('confirmPurchase')}
        confirmLabel={_('yes')}
        onCancel={() => setConfirmBuy(false)}
        onConfirm={handleBuy}
      >
        {_('confirm.buyentry', { item: item.name })}
      </ConfirmModal>
    </ContentContainer>
  );
};

export const ProductContributionContainer: React.FC<{ product: ProductType }> = ({ product }) => {
  const item = getItemFromProduct(product, product.id);
  const contribution = item.contribution;
  if (!contribution) {
    throw new Error('Unexpected product received.');
  }

  const { id: userId, balance } = useUserStore();
  const alertStore = useAlertStore();
  const setLoadingOverlayOpened = useLoadingOverlay((s) => s.setOpened);

  const contribQuery = useContributionEntry(item.id, userId);
  const mutation = useMakeContributionMutation(item.id, userId);

  const handleMakeContribution = async (amount: number) => {
    setLoadingOverlayOpened(true);
    mutation.mutate(amount, {
      onSuccess: () => {
        alertStore.addMessageAsToast(_('contribution.contributionMade'));
      },
      onSettled: () => {
        setLoadingOverlayOpened(false);
      },
    });
  };

  return (
    <ProductItemContribution
      product={product}
      item={item as any}
      balance={balance}
      contribQuery={contribQuery}
      locked={mutation.isLoading}
      onContribute={handleMakeContribution}
      error={makeErrorFromMutation(mutation)}
      onErrorClose={() => mutation.reset()}
    />
  );
};

const ProductItemContribution: React.FC<
  {
    product: ProductType;
    item: ItemTypeDef & { contribution: ItemContributionData };
    contribQuery: UseQueryResult<ContributionEntry>;
    balance: number;
    locked: boolean;
    onContribute: (amount: number) => void;
  } & ErrorProps
> = ({ product, item, locked, contribQuery, balance, onContribute, error, onErrorClose }) => {
  const contribution = item.contribution;

  const maxAmount = Math.max(0, contribution.max - contribution.coins);
  const defaultAmount = 0; // Math.min(Math.floor(balance * 0.2), maxAmount);
  const [amount, setAmount] = useState(defaultAmount);
  const [confirmContribution, setConfirmContribution] = useState(false);

  // Reset the amount when the item or balance change.
  useEffect(() => setAmount(defaultAmount), [item.id, balance]); // eslint-disable-line

  const isClosed = !isAvailable(product) || maxAmount <= 0;
  const canContribute = !locked && !isClosed && balance >= amount && amount > 0;

  const handleMakeContribution = () => {
    setConfirmContribution(false);
    onContribute(amount);
  };

  return (
    <ContentContainer className="mb-4">
      <Item
        type={getTypeName(item.type)}
        name={product.name}
        image={item.image_url}
        description={item.description}
        error={error}
        onErrorClose={onErrorClose}
      >
        <ContributionForm
          currencySymbol={contribution.currency_symbol}
          currencyValue={contribution.currency_value}
          canContribute={canContribute}
          canChangeAmount={!locked && !isClosed}
          contribQuery={contribQuery}
          amount={amount}
          goal={contribution.goal}
          totalContributed={contribution.coins}
          endDate={new Date(item.available_until * 1000)}
          onAmountChange={(amount) => setAmount(Math.min(maxAmount, Math.max(0, amount)))}
          onMakeContribution={() => setConfirmContribution(true)}
        />
      </Item>
      <ConfirmModal
        opened={confirmContribution}
        title={_('common:confirm')}
        confirmLabel={_('yes')}
        onCancel={() => setConfirmContribution(false)}
        onConfirm={handleMakeContribution}
      >
        {_('confirm.makeContribution', { item: item.name })}
      </ConfirmModal>
    </ContentContainer>
  );
};
