import React from 'react';
import NumberSelector from './NumberSelector';
import Coins from './Coins';
import { _ } from '../lib/l18n';

const CartItem: React.FC<{
  image: string;
  name: string;
  description: string;
  quantity: number;
  total: number;
  onQuantityChange: (quantity: number) => void;
  onRemove: () => void;
}> = ({ image, name, onQuantityChange, onRemove, description, quantity, total }) => {
  const handleRemove = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onRemove();
  };
  return (
    <div className="md:flex">
      <div className="mb-4 md:mb-0 md:w-44 md:flex-shrink-0">
        <img src={image} alt="" className="w-full" />
      </div>
      <div className="md:flex-1 md:flex md:flex-col md:ml-6 md:justify-between">
        <div>
          <h3 className="text-xl mb-2">{name}</h3>
          <div className="mb-3 opacity-60 text-grey-2">{description}</div>
        </div>
        <div className="flex">
          <div className="flex-1">
            <div className="mb-3 ">
              <NumberSelector value={quantity} onChange={onQuantityChange} />
            </div>
            <div>
              <Coins amount={total} className="text-xl font-medium" />
            </div>
          </div>
          <div className="self-end md:self-start">
            <a href="#remove" onClick={handleRemove}>
              {_('remove')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
