import React, { useEffect } from 'react';
import { Repository } from '../lib/repository';
import {
  useBrandingStore,
  usePurchasesStore,
  useStoreListingStore,
  useRepository,
  useUserStore,
  useLeaderboardStore,
} from '../state/store';
import LoadingScreenContainer from './LoadingScreen';

const AppLoader: React.FC<{ repository: Repository }> = ({ repository, children }) => {
  const setRepository = useRepository((s) => s.setRepository);
  const { loaded, loading, loadProducts } = useStoreListingStore();
  const {
    loaded: leaderboardLoaded,
    loading: leaderboardLoading,
    anyEnabled: anyLeaderboardEnabled,
    loadLeaderboardEnabled,
  } = useLeaderboardStore();
  const { loaded: brandingLoaded, loading: brandingLoading, loadBranding } = useBrandingStore();
  const loadUser = useUserStore((state) => state.loadUser);
  const loadPurchases = usePurchasesStore((state) => state.loadPurchases);

  const hasCheckedLeaderboard = leaderboardLoaded || anyLeaderboardEnabled;
  const isLoading = loading || brandingLoading || leaderboardLoading;
  const isLoaded = loaded && brandingLoaded && hasCheckedLeaderboard;

  // When the repository changes.
  useEffect(() => {
    setRepository(repository);
    if (isLoaded || isLoading) return;
    (async () => {
      loadProducts(repository);
      loadBranding(repository);
      loadLeaderboardEnabled(repository);
      loadUser(repository);
      loadPurchases(repository);
    })();
  }, [repository]); // eslint-disable-line

  if (!isLoaded) return <LoadingScreenContainer />;

  return <>{children}</>;
};

export default AppLoader;
