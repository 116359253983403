import * as yup from 'yup';
import { _ } from './l18n';
import { getCountriesWithStateList } from './world';

let yupLocalised = false;

export const getShippingRedemptionSchema = (additionalRequiredFields?: string[]) => {
  const yup = getYup();
  const company = yup.string().default('');
  const phone = yup
    .string()
    .default('')
    .matches(/\+?[0-9 .)(-]{8,}/, { message: _('validation:enterPhoneNumber'), excludeEmptyString: true });
  return yup
    .object({
      company: additionalRequiredFields?.includes('company') ? company.required() : company,
      firstname: yup.string().required(),
      lastname: yup.string().required(),
      email: yup.string().required().email(),
      phone: additionalRequiredFields?.includes('phone') ? phone.required() : phone,
      addressline1: yup.string().required(),
      addressline2: yup.string().default(''),
      city: yup.string().required(),
      postcode: yup.string().required(),
      state: yup
        .string()
        .default('')
        .when('country', (country: string, schema: yup.StringSchema) =>
          getCountriesWithStateList().includes(country) ? schema.required() : schema
        ),
      country: yup.string().lowercase().length(2).required(),
    })
    .noUnknown();
};

export const getYup = () => {
  if (!yupLocalised) {
    yup.setLocale({
      mixed: {
        required: _('validation:requiredField'),
      },
      string: {
        email: _('validation:invalidEmailAddress'),
        required: _('validation:requiredField'),
      },
    });
    yupLocalised = true;
  }
  return yup;
};

export const isEmailValid = (email: string) => {
  return Boolean(email.match(/^[^@\s]+@[^@\s]+\.[a-z]{2,}$/i));
};
