import React from 'react';
import { useIsEmbedded } from '../../lib/hooks';

const ContentContainer: React.FC<{ className?: string; style?: React.CSSProperties }> = ({ className = '', style, children }) => {
  const isEmbedded = useIsEmbedded();
  return (
    <div className={`container px-6 ${!isEmbedded ? 'mx-auto' : ''} ${className}`} style={style}>
      {children}
    </div>
  );
};

export default ContentContainer;
