import React, { useEffect } from 'react';
import { useRecentActivityStore, useRepository } from '../state/store';
import RecentActivity from '../components/RecentActivity';
import { exists, _ } from '../lib/l18n';

const RecentActivityContainer = () => {
  const repository = useRepository((s) => s.repository);
  const state = useRecentActivityStore();

  useEffect(() => {
    state.loadItems(repository);
  }, []); // eslint-disable-line

  return (
    <RecentActivity
      items={state.items.map((i) => {
        return {
          amount: i.amount,
          description: exists(i.lang.string) ? _(i.lang.string, i.lang.args) : i.summary,
          date: new Date(i.recorded_on * 1000),
        };
      })}
      loading={state.loading}
    />
  );
};

export default RecentActivityContainer;
