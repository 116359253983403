import React, { useEffect, useState } from 'react';
import { useRepository } from '../state/store';
import Information from '../components/Information';

const InformationContainer = () => {
  const repository = useRepository((s) => s.repository);
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState({ type: 'text/plain', content: '' });

  useEffect(() => {
    (async () => {
      const resp = await repository.getSectionInformation();
      setInfo(resp.info);
      setLoading(false);
    })();
  }, []); // eslint-disable-line

  return <Information type={info.type} content={info.content} loading={loading} />;
};

export default InformationContainer;
