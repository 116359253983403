import React, { useEffect, useState } from 'react';
import { _ } from '../lib/l18n';
import { Purchase, PurchaseState } from '../lib/types';
import { RedemptionFilter } from '../lib/types.local';
import { getRedemptionsFilterUrl, redemptionsUrl } from '../lib/urls';
import Button from './Button';
import ConfirmModal from './ConfirmModal';
import PageWithSideMenu, { SideMenuItem } from './layouts/PageWithSideMenu';
import Notification from './Notification';

const Redemptions: React.FC<{
  defaultNewRedemptions?: number; // The number of new redemptions when the component was bound.
  filter: RedemptionFilter;
  redemptions: Purchase[];
  onRedeem: (redemption: Purchase[]) => void;
}> = ({ filter, redemptions, onRedeem, defaultNewRedemptions = 0 }) => {
  const [newRedemptions, setNewRedemptions] = useState(0);
  const [confirmRedeem, setConfirmRedeem] = useState<Purchase[]>([]);

  let displayRedemptions = redemptions;
  let displayingPending = filter === RedemptionFilter.Pending;
  let showRedeemAll = displayingPending;

  if (filter === RedemptionFilter.Pending) {
    displayRedemptions = displayRedemptions.filter((r) => r.state !== PurchaseState.Redeemed);
  } else if (filter === RedemptionFilter.Completed) {
    displayRedemptions = displayRedemptions.filter((r) => r.state === PurchaseState.Redeemed);
  }

  const maxRedeemAtOnce = 25;
  const redeemableRedemptions = displayRedemptions.filter((r) => r.state === PurchaseState.Made);
  showRedeemAll = showRedeemAll && redeemableRedemptions.length > 1;

  useEffect(() => {
    if (defaultNewRedemptions <= 0) return;
    setNewRedemptions(defaultNewRedemptions);
  }, [defaultNewRedemptions]);

  const handleConfirmRedeem = (redemption: Purchase) => {
    onRedeem([redemption]);
    // setConfirmRedeem([redemption]);
  };

  const handleRedeem = async () => {
    onRedeem(confirmRedeem);
    setConfirmRedeem([]);
  };

  const handleConfirmRedeemAll = () => {
    onRedeem(redeemableRedemptions.slice(0, maxRedeemAtOnce));
    // setConfirmRedeem(redeemableRedemptions.slice(0, maxRedeemAtOnce));
  };

  return (
    <PageWithSideMenu
      renderMenuItems={() => (
        <>
          <SideMenuItem isActive={isRedemptionPendingLinkActive} to={getRedemptionsFilterUrl(RedemptionFilter.Pending)}>
            {_('redemptionsPending')}
          </SideMenuItem>
          <SideMenuItem to={getRedemptionsFilterUrl(RedemptionFilter.Completed)}>{_('redemptionsCompleted')}</SideMenuItem>
        </>
      )}
      menuTitle={_('redemptions.title')}
    >
      <div>
        {displayRedemptions.length ? (
          <>
            {newRedemptions && displayingPending ? (
              <div className="mb-6">
                <Notification onClose={() => setNewRedemptions(0)}>
                  {_('redemptions.youWonNewRewardsRedeemThem', { number: newRedemptions })}
                </Notification>
              </div>
            ) : null}
            {displayRedemptions.map((redemption) => {
              const showRedeemButton = redemption.state === PurchaseState.Made;
              let desc;

              if (redemption.state === PurchaseState.Redeeming) {
                desc = _('redemptionPending');
              } else if (redemption.state === PurchaseState.Redeemed && redemption.redeemed_on) {
                desc = _('redemptionRedeemedOn', { date: new Date(redemption.redeemed_on * 1000).toLocaleDateString() });
              }

              return (
                <RedemptionItem
                  key={redemption.id}
                  image={redemption.item.image_url}
                  name={redemption.item.name}
                  onRedeem={() => showRedeemButton && handleConfirmRedeem(redemption)}
                  description={desc}
                  showRedeemButton={showRedeemButton}
                />
              );
            })}
          </>
        ) : (
          <div className="text-xl text-center rounded-lg bg-grey-light p-8 py-20">{_('redemptions.noRedemptions')}</div>
        )}
      </div>
      <div className="flex justify-end">
        <div className="min-w-full md:min-w-64">
          {showRedeemAll ? (
            <Button onClick={handleConfirmRedeemAll} primary>
              {redeemableRedemptions.length > maxRedeemAtOnce
                ? _('redemptions.redeemN', { number: maxRedeemAtOnce })
                : _('redemptions.redeemAll')}
            </Button>
          ) : null}
        </div>
      </div>
      <ConfirmModal
        opened={Boolean(confirmRedeem.length)}
        title={_('redemptions.confirm')}
        cancelLabel={_('no')}
        confirmLabel={_('yes')}
        onCancel={() => setConfirmRedeem([])}
        onConfirm={handleRedeem}
      >
        {confirmRedeem.length > 1
          ? _('confirm.redeemitems', { number: confirmRedeem.length })
          : _('confirm.redeemitem', { item: confirmRedeem[0]?.item.name || '?' })}
      </ConfirmModal>
    </PageWithSideMenu>
  );
};

const RedemptionItem: React.FC<{
  image: string;
  description?: string;
  name: string;
  showRedeemButton?: boolean;
  onRedeem?: () => void;
}> = ({ image, description, name, onRedeem, showRedeemButton = true }) => {
  return (
    <div className="md:flex mb-6 pb-6 border-b border-silver-3">
      <div className="md:pl-1 mb-4 md:mb-0 md:w-40 md:flex-shrink-0">
        <img src={image} alt="" className="w-full" />
      </div>
      <div className="md:flex-1 md:flex md:flex-col md:ml-6 md:justify-between md:py-2">
        <div>
          <h3 className="text-xl mb-2">{name}</h3>
          <div className="mb-3 opacity-60 text-grey-2">{description}</div>
        </div>
        <div className="flex">
          <div className="min-w-44 max-w-full">{showRedeemButton ? <Button onClick={onRedeem}>{_('redeem')}</Button> : null}</div>
        </div>
      </div>
    </div>
  );
};

function isRedemptionPendingLinkActive(match: any, location: any) {
  return match || location.pathname === redemptionsUrl;
}

export default Redemptions;
