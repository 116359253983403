import React, { useEffect, useState } from 'react';
import cartSrc from '../assets/icons/cart-large.svg';
import Cart from '../components/Cart';
import CartItem from '../components/CartItem';
import CartItemList from '../components/CartItemList';
import ConfirmModal from '../components/ConfirmModal';
import { useRedemptionActivator } from '../lib/hooks';
import { getItemFromProduct, getRemainingStock, isAvailable, isItemFromProduct } from '../lib/item';
import { _ } from '../lib/l18n';
import { CartItem as CartItemType, Product } from '../lib/types.local';
import { useCartStore, useLoadingOverlay, useRepository, useStoreListingStore, useUserStore } from '../state/store';

/**
 * Get the list of cart items which should be removed or updated.
 *
 * @param cartItems The list of cart items.
 * @param products The list of available products.
 */
const getCartItemsMismatch = (cartItems: CartItemType[], products: Product[]) => {
  return cartItems
    .map((cartItem) => {
      const itemId = cartItem.id;
      const product = products.find((p) => isItemFromProduct(p, itemId));
      if (!product) {
        return [itemId, 0];
      }
      const item = getItemFromProduct(product, itemId);
      if (!item) {
        return [itemId, 0];
      }
      const stock = getRemainingStock(item);
      if (!isAvailable(item) || !stock) {
        return [itemId, 0];
      }
      const newQty = Math.min(cartItem.quantity, stock);
      if (newQty !== cartItem.quantity) {
        return [itemId, newQty];
      }
      return null;
    })
    .filter((c) => c !== null) as [string, number][];
};

const CartContainer = () => {
  const state = useCartStore();
  const repository = useRepository((state) => state.repository);
  const { loadProducts, products } = useStoreListingStore();
  const { loadBalance } = useUserStore();
  const setLoadingOverlayOpened = useLoadingOverlay((s) => s.setOpened);
  const { redeem } = useRedemptionActivator();

  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState<string>();

  const total = state.items.reduce((c, i) => c + i.quantity * i.cost, 0);
  const totalQuantity = state.items.reduce((c, i) => c + i.quantity, 0);
  const isEmpty = state.items.length <= 0;

  // On initial load, update the products.
  useEffect(() => {
    if (isEmpty) return;
    loadProducts(repository);
  }, []); // eslint-disable-line

  // Every time we update the products, we check for mismatch.
  useEffect(() => {
    const itemsToUpdate = getCartItemsMismatch(state.items, products);
    if (!itemsToUpdate.length) return;
    itemsToUpdate.forEach(([itemId, qty]) => {
      if (!qty) {
        state.remove(itemId);
      } else {
        state.setQuantity(itemId, qty);
      }
    });
    setError(_('someCartItemsNotAvailableCartUpdated'));
  }, [products]); // eslint-disable-line

  const handleErrorClose = () => setError(undefined);
  const handlePlaceOrderConfirm = () => {
    setConfirm(true);
  };

  const handlePlaceOrder = async () => {
    setConfirm(false);
    if (!state.items.length) return;

    setError(undefined);
    setLoadingOverlayOpened(true);

    let purchases = [];
    try {
      purchases = await repository.purchaseItems(state.items.map((i) => ({ item_id: i.id, quantity: i.quantity })));
    } catch (err) {
      let error = _('error.unknown');
      if (err.code === 'NOT_AVAILABLE' || err.code === 'INVALID_ITEM') {
        error = _('someCartItemsNotAvailableCartUpdated');
        loadProducts(repository);
      }
      setError(error);
      setLoadingOverlayOpened(false);
      return;
    }

    // We're done!
    state.empty();
    setLoadingOverlayOpened(false);
    loadProducts(repository); // TODO Let react-query do this!
    loadBalance(repository); // TODO Let react-query do this!
    redeem(purchases);

    // Code used when the redemption was not instant.
    // state.empty();
    // alertStore.addMessageAsToast(_('itemsPurchased'));
    // userStore.loadBalance(repository);
    // loadPurchases(repository);
    // loadProducts(repository);
  };

  const handleQuantityChange = (itemId: string, qty: number) => {
    const product = products.find((p) => isItemFromProduct(p, itemId));
    if (!product) {
      state.remove(itemId);
      return;
    }
    const item = getItemFromProduct(product, itemId);
    if (!item) {
      state.remove(itemId);
      return;
    }
    state.setQuantity(itemId, Math.min(Math.min(25, getRemainingStock(item)), Math.max(1, qty)));
  };

  return (
    <Cart
      total={total}
      onPlaceOrder={handlePlaceOrderConfirm}
      canPlaceOrder={totalQuantity > 0 && totalQuantity <= 25}
      maxQuantity={totalQuantity > 25 ? 25 : 0}
      maxQuantityExceeded={totalQuantity > 25}
      error={error}
      onErrorClose={handleErrorClose}
    >
      {!isEmpty ? (
        <CartItemList>
          {state.items.map((item) => {
            return (
              <CartItem
                key={item.id}
                image={item.thumbnail_url}
                name={item.name}
                description={item.description}
                quantity={item.quantity}
                total={item.quantity * item.cost}
                onRemove={() => state.remove(item.id)}
                onQuantityChange={(qty) => handleQuantityChange(item.id, qty)}
              />
            );
          })}
        </CartItemList>
      ) : (
        <div className="flex items-center flex-col text-center rounded-lg py-12 px-8 bg-grey-light">
          <img src={cartSrc} alt="" className="w-40" />
          <h2 className="text-3xl mt-8">{_('yourCartEmpty')}</h2>
          <p className="text-2xl leading-snug text-grey-steel mt-2">{_('checkOutShopToAddItems')}</p>
        </div>
      )}
      <ConfirmModal
        opened={confirm}
        title={_('confirmPurchase')}
        confirmLabel={_('yes')}
        onCancel={() => setConfirm(false)}
        onConfirm={handlePlaceOrder}
      >
        {_('confirm.placeorder')}
      </ConfirmModal>
    </Cart>
  );
};

export default CartContainer;
